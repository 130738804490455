text {
  font-weight: bold;
}

#main__dashboard #dashboard__grid {
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: 100px 200px 200px auto auto;
  grid-template-columns: 33.333% 33.333% 33.333%;
}
#main__dashboard #dashboard__grid #grid__buttons {
  display: flex;
  grid-area: 1/1/span 1/span 3;
}
#main__dashboard #dashboard__grid #grid__buttons > div {
  width: 25%;
  height: 100%;
  margin-right: 15px;
  background: #F8F8F8;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}
#main__dashboard #dashboard__grid #grid__buttons > div:hover {
  transform: scale(1.03);
}
#main__dashboard #dashboard__grid #grid__buttons > div:last-child {
  margin-right: 0;
}
#main__dashboard #dashboard__grid #grid__buttons > div button, #main__dashboard #dashboard__grid #grid__buttons > div a {
  display: block;
  width: 100%;
  text-align: center;
  height: 100px;
  line-height: 100px;
  color: #1a1a1a;
  text-decoration: none;
  border: none;
}
#main__dashboard #dashboard__grid #grid__buttons > div button svg, #main__dashboard #dashboard__grid #grid__buttons > div a svg {
  display: none;
}
#main__dashboard #dashboard__grid #grid__buttons > div button p, #main__dashboard #dashboard__grid #grid__buttons > div a p {
  margin: 0;
  color: inherit;
}
#main__dashboard #dashboard__grid #grid__resources {
  grid-area: 2/1/span 1/span 1;
}
#main__dashboard #dashboard__grid #grid__next-print {
  grid-area: 2/1/span 2/span 1;
}
#main__dashboard #dashboard__grid #grid__main-block {
  grid-area: 2/2/span 2/span 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
#main__dashboard #dashboard__grid #grid__main-block > div {
  width: 50%;
}
#main__dashboard #dashboard__grid #grid__main-block #usage-repartition-duration, #main__dashboard #dashboard__grid #grid__main-block #usage-repartition-quantity {
  width: 50%;
}
#main__dashboard #dashboard__grid #grid__stats {
  grid-area: 4/1/span 1/span 3;
  display: flex;
}
#main__dashboard #dashboard__grid #grid__stats #most-used-printers, #main__dashboard #dashboard__grid #grid__stats #resources-used-by-technologies {
  width: 50%;
}
#main__dashboard #dashboard__grid #grid__printers {
  grid-area: 5/1/span 1/span 3;
}
#main__dashboard #dashboard__grid #grid__printers > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#main__dashboard #dashboard__grid #grid__printers > div > div {
  min-width: 200px;
  background: #f5f5f5;
  padding: 7px 15px;
  display: inline-block;
  margin: 0 15px 15px 0;
  border-radius: 4px;
}
#main__dashboard #dashboard__grid #grid__printers > div > div p {
  margin: 0;
}
#main__dashboard #dashboard__grid #grid__printers > div > div .printer-state {
  display: inline-block;
  font-size: 12px;
  color: white;
  border-radius: 1000px;
  cursor: default;
  user-select: none;
  width: 10px;
  height: 10px;
  margin-right: 7px;
}
#main__dashboard #dashboard__grid #grid__printers > div > div .printer-state.printer-state--free {
  background: #2CEAA8;
}
#main__dashboard #dashboard__grid #grid__printers > div > div .printer-state.printer-state--upcoming {
  background: orange;
}
#main__dashboard #dashboard__grid #grid__printers > div > div .printer-state.printer-state--working {
  background: #F9224B;
}
#main__dashboard #dashboard__grid #grid__gantt {
  grid-area: 6/1/span 1/span 3;
}
#main__dashboard #dashboard__grid .dashboard-block {
  background: #F8F8F8;
  border-radius: 10px;
  padding: 15px;
  box-sizing: content-box;
}
#main__dashboard #dashboard__grid .dashboard-block h2 {
  margin-top: 0;
}
